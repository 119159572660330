var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header',{attrs:{"propsObj":_vm.page}}),_c('HomeSchoolBar',_vm._b({},'HomeSchoolBar',{
            showText: false,
            title_left: _vm.homepage.bar_title_left,
            link_text_left: _vm.homepage.bar_link_text_left,
            link_left: _vm.homepage.bar_link_left,
            content_left: _vm.homepage.bar_content_left,
            title_right: _vm.homepage.bar_title_right,
            link_text_right: _vm.homepage.bar_link_text_right,
            link_right: _vm.homepage.bar_link_right,
            content_right: _vm.homepage.bar_content_right,
        },false)),_c('Intro',{attrs:{"title":_vm.page.intro_title,"content":_vm.page.intro_content}}),_c('div',{staticClass:"container mt-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9"},_vm._l((_vm.quarks),function(q){return _c('div',{key:q.id,staticClass:"row mb-3",class:{ qt: q.config == 1 }},_vm._l((q.nrCols),function(c,index){return _c('div',{key:index,class:q.colStyle[index]},[(index == q.titleCol)?_c('p',{staticClass:"fg-pink text-uppercase mb-0",domProps:{"innerHTML":_vm._s(q.title)}}):_vm._e(),(q.columns[index].indexOf('***mailmij***') != -1)?_c('span',[_c('button',{staticClass:"btn bg-apricot text-white",on:{"click":function($event){return _vm.sendMail('Afspraak maken')}}},[_vm._v(" Mail mij om een afspraak te maken ")])]):(q.columns[index].indexOf('***mailmij2***') != -1)?_c('span',[_c('button',{staticClass:"btn bg-pink text-white",on:{"click":function($event){return _vm.sendMail('aanmelden symposium vrijdag 17 november 2023')}}},[_vm._v(" JA! ik wil erbij zijn op 17 november 2023 ")])]):(q.columns[index].indexOf('***aanmelden***') != -1)?_c('span',[_c('button',{staticClass:"btn bg-pink text-white",on:{"click":function($event){return _vm.sendMail('Aanmelden retraite')}}},[_vm._v(" Ik meld me aan ")])]):(q.columns[index].indexOf('***bestelmij***') != -1)?_c('span',[_c('button',{staticClass:"btn bg-apricot text-white",on:{"click":function($event){return _vm.orderBook(222)}}},[_vm._v(" Klik hier om het boek te bestellen ")])]):_c('div',{staticClass:"mt-2",domProps:{"innerHTML":_vm._s(q.columns[index])}})])}),0)}),0),_c('div',{staticClass:"col-md-3"},[_c('Avatar',{attrs:{"content":_vm.page.avatar}}),_c('BtnAgenda'),_c('BtnImage',{attrs:{"bgColor":_vm.sideButton.bgColor,"title":_vm.sideButton.title,"content":_vm.sideButton.content,"image":_vm.sideButton.image,"link":_vm.sideButton.link}}),_c('BtnReferences'),_c('SocialMedia')],1)])]),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }