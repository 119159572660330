<template>
    <div>
        <!-- top image bar -->
        <Header :propsObj="page"></Header>

        <!-- at home - logo button - at school -->
        <HomeSchoolBar v-bind="{
            showText: false,
            title_left: homepage.bar_title_left,
            link_text_left: homepage.bar_link_text_left,
            link_left: homepage.bar_link_left,
            content_left: homepage.bar_content_left,
            title_right: homepage.bar_title_right,
            link_text_right: homepage.bar_link_text_right,
            link_right: homepage.bar_link_right,
            content_right: homepage.bar_content_right,
        }"></HomeSchoolBar>

        <!-- introduction -->
        <Intro :title="page.intro_title" :content="page.intro_content"></Intro>

        <!-- content -->
        <!-- <div class="container">
			<div class="row">
				<div class="col-9">
					<BtnImage
						bgColor="" aspect="3"
					></BtnImage>
				</div>
			</div>
		</div>-->

        <!-- content -->
        <div class="container mt-5">
            <div class="row">
                <!-- left part with rows -->
                <div class="col-md-9">
                    <div class="row mb-3" v-for="q in quarks" :key="q.id" :class="{ qt: q.config == 1 }">
                        <div v-for="(c, index) in q.nrCols" :key="index" :class="q.colStyle[index]">
                            <p v-if="index == q.titleCol" class="fg-pink text-uppercase mb-0" v-html="q.title"></p>
                            <span v-if="q.columns[index].indexOf('***mailmij***') != -1">
                                <button class="btn bg-apricot text-white" @click="sendMail('Afspraak maken')">
                                    Mail mij om een afspraak te maken
                                </button>
                            </span>
                            <span v-else-if="q.columns[index].indexOf('***mailmij2***') != -1">
                                <button class="btn bg-pink text-white"
                                    @click="sendMail('aanmelden symposium vrijdag 17 november 2023')">
                                    JA! ik wil erbij zijn op 17 november 2023
                                </button>
                            </span>
                            <span v-else-if="q.columns[index].indexOf('***aanmelden***') != -1">
                                <button class="btn bg-pink text-white"
                                    @click="sendMail('Aanmelden retraite')">
                                    Ik meld me aan
                                </button>
                            </span>
                            <span v-else-if="q.columns[index].indexOf('***bestelmij***') != -1">
                                <button class="btn bg-apricot text-white" @click="orderBook(222)">
                                    Klik hier om het boek te bestellen
                                </button>
                            </span>
                            <div v-else class="mt-2" v-html="q.columns[index]"></div>
                        </div>
                        <!-- <div class="col-4 fg-pink" :class="{'border-left':q.col2!=''}" v-html="q.col2"></div> -->
                    </div>
                </div>

                <!-- right sidebar -->
                <div class="col-md-3">
                    <Avatar :content="page.avatar"></Avatar>
                    <BtnAgenda></BtnAgenda>
                    <BtnImage :bgColor="sideButton.bgColor" :title="sideButton.title" :content="sideButton.content"
                        :image="sideButton.image" :link="sideButton.link"></BtnImage>
                    <BtnReferences></BtnReferences>
                    <SocialMedia></SocialMedia>
                </div>
            </div>
        </div>

        <!-- footer  -->
        <Footer></Footer>
    </div>
</template>

<script>
import axios from 'axios';
import utils from '@/js/utils';

import Avatar from '@/components/Avatar.vue';
import BtnAgenda from '@/components/BtnAgenda.vue';
import BtnReferences from '@/components/BtnReferences.vue';
import BtnImage from '@/components/BtnImage.vue';
import Header from '@/components/Header.vue';
import HomeSchoolBar from '@/components/HomeSchoolBar.vue';
import Intro from '@/components/Intro.vue';
import SocialMedia from '@/components/SocialMedia.vue';
import Footer from '@/components/Footer.vue';

export default {
    data() {
        return {
            avatar: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt',
            sideButton: {
                bgColor: '',
                title: "simone's blog",
                content: 'Mijn kennis en bijzondere ervaringen - die deel ik graag met jou!',
                image: '/uploads/pencils.jpg',
                link: '/blog',
            },
            homepage: {},
        };
    },

    props: {
        route: String,
    },

    components: {
        Avatar,
        BtnAgenda,
        BtnReferences,
        BtnImage,
        Header,
        HomeSchoolBar,
        Intro,
        SocialMedia,
        Footer,
    },

    computed: {
        quarks() {
            let qq = this.$store.getters.quarks;
            // console.log('main.vue computed quarks', quarks.length);
            if (qq.length > 0) {
                this.handleQuarks(qq);
                // console.log(qq);
                return qq;
            }
        },
        page() {
            let pp = this.$store.getters.page(this.route);
            if (pp.header_image == 'afbeelding') {
                pp.header_image = '../uploads/blokken.jpg';
            }
            return pp;
        },
    },

    created: function () {
        console.clear();
        // var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
        // var localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -5).replace('T', ' ');
        // console.log('main', localISOTime);
        // this.page = this.$store.getters.page(this.route);
        // console.log('page', this.route, this.page);
        this.homepage = this.$store.getters.page('');
    },

    methods: {
        handleQuarks(arr) {
            // console.log('arr', arr.length, arr);
            utils.sortByRank(arr);

            arr.forEach((q) => {
                // columns
                // if (q.cols == null || q.cols == '') {
                // 	q.cols = ['12'];
                // } else {
                // 	q.cols = utils.strip_tags(q.cols);
                // 	q.cols = q.cols.split(';');
                // }

                // console.log('q', q);

                // determine which column title belongs to
                if (q.title != null) {
                    let spl = q.title.split(';');
                    if (spl.length == 1) {
                        q.titleCol = 0;
                    } else {
                        q.title = spl[0];
                        q.titleCol = spl[1];
                    }
                }

                // create column styles
                if (!Array.isArray(q.cols)) q.cols = q.cols.split(';');
                q.colStyle = [];
                let i = 0;
                q.cols.forEach((style) => {
                    q.colStyle[i++] = 'col-md-' + style;
                });

                // console.log('cols', q.cols);
                // console.log(q.colStyle);

                // let arr = q.cols.split(';');
                q.nrCols = q.cols.length;

                q.columns = [];
                q.columns.push(q.col1);
                q.columns.push(q.col2);
                q.columns.push(q.col3);
                q.columns.push(q.col4);

                // check all columns
                for (let i = 0; i < q.columns.length; i++) {
                    // console.log('el', el);
                    let el = q.columns[i];
                    if (el != null) {
                        // check for image
                        if (el.indexOf('<img') != -1) {
                            let img = utils.strip_tags(el, '<img>');
                            q.columns[i] = utils.img_fluid(img);
                            // console.log('img-fluid', q.columns[i]);
                        }

                        // check for markers
                        if (q.columns[i].indexOf('***mailmij***') != -1) {
                            q.columns[i] = q.columns[i].replace(
                                '***mailmij***',
                                '<span class="text-white>***mailmij***</span>'
                            );
                        }
                        if (q.columns[i].indexOf('***bestelmij***') != -1) {
                            q.columns[i] = q.columns[i].replace(
                                '***bestelmij***',
                                '<span class="text-white>***bestelmij***</span>'
                            );
                        }
                    }
                }
            });
        },

        sendMail(message) {
            window.location.href = 'mailto:info@simonebusscher.nl?subject=' + message;
        },

        async orderBook(idBook) {
            let BASE_URL = '/php/cms.php';
            let TABLE_PRICES = '/prices/id/';

            let cart = [];
            let prices = [];
            let url = BASE_URL + TABLE_PRICES + idBook;

            await axios
                .get(url)
                .then((response) => {
                    //console.log('response.data', response.data.length);
                    if (!response.data.error) {
                        for (let i = 0; i < response.data.length; i++) {
                            // console.log('p', response.data[i]);
                            if (response.data[i].visible != '-') {
                                prices.push(response.data[i]);
                            }
                        }
                        console.log('item', prices[prices.length - 1]);
                    } else {
                        console.log('Error', response.data.error.code, ':', response.data.error.status);
                    }
                })
                .catch((e) => {
                    // this.errors.push(e)
                    //console.log(e);
                });

            // add to cart {
            const element = prices[prices.length - 1];
            let present = false;
            for (let i = 0; i < cart.length; i++) {
                if (element.id === cart[i].id) {
                    present = true;
                }
            }

            if (!present) {
                element.quantity = 1;
                cart.push(element);
            }

            console.log(cart);

            // save to local storage
            sessionStorage.setItem('cart', JSON.stringify(cart));
            this.$router.push('shoppingcart');
        },
    },
};
</script>

<style>
a {
    color: #be1622;
}

a:hover {
    color: #f39200;
    text-decoration: none;
}

.qt {
    color: white;
    background-color: #f39200;
}

.divv {
    display: table;
    height: 100%;
    width: 100%;
    text-align: center;
    border: 2px dashed #f69c55;
}

span {
    display: table-cell;
    vertical-align: middle;
}
</style>
