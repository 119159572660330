<template>
	<div class="text-center p-3 border-top border-bottom">
		<img src="/uploads/simone-busscher.jpg" alt="foto simone" class="rounded-circle w-50 my-3" />
		<p class="quote fg-apricot">‘{{content}}’</p>
	</div>
</template>

<script>
export default {
	props: {
		content: { type: String }
	}
};
</script>